import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import Button, { ButtonIconPositions, ButtonIconTypes, ButtonStyles } from 'components/layout/Button';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutProductSelectHorizontal: FunctionComponent<Props> = ({
    defaultOption,
    options,
    placeholder,
    onChange,
    className,
}) => {
    const [selectedOption, setSelectedOption] = useState<any>(defaultOption || options?.[0] || { value: null, label: placeholder });
    const [selectedOptionIdx, setSelectedOptionIdx] = useState<number>(0);
    const [translateValue, setTranslateValue] = useState<number>(0);

    const choosedValue = selectedOption?.value;
    const maxValue = options?.[options.length - 1]?.value;
    const widthPercent = `${Number((choosedValue / maxValue) * 100).toFixed(0)}%`;

    const oneElementHeight = 33;

    const handleOnOptionClick = (optionIdx: number): void => {
        const opt = options[optionIdx];

        setSelectedOptionIdx(optionIdx);
        setSelectedOption(opt);

        if (onChange) onChange(opt);
    };

    return (
        <StyledComponent className={classnames('public-layout-product-select-horizontal', className)}>
            <div className="progress-wrapper">
                <div className="progress-wrapper__content">
                    <Button
                        style={ButtonStyles.None}
                        className="add-button"
                        onClick={() => {
                            if (selectedOptionIdx > 0) {
                                handleOnOptionClick(selectedOptionIdx - 1);
                                setTranslateValue(prevState => prevState + oneElementHeight);
                            }
                        }}
                        icon={{
                            type: ButtonIconTypes.Element,
                            value: (
                                <img src="/theme/images/product-select-horizontal/subtract.svg" />
                            ),
                            position: ButtonIconPositions.Before,
                        }}
                    />
                    <div
                        className={classnames('progress-wrapper__scroller scroller', {
                            'scroller--decimal': !isNaN(choosedValue) && choosedValue >= 10,
                        })}
                        style={{ transform: `translateY(${translateValue}px)` }}
                    >
                        {options?.map(option => (
                            <div
                                key={option.value}
                                className="scroller__item"
                            >
                                {option.value}
                            </div>
                        ))}
                    </div>
                    <Button
                        style={ButtonStyles.None}
                        className="subtract-button"
                        onClick={() => {
                            if (selectedOptionIdx < options.length - 1) {
                                handleOnOptionClick(selectedOptionIdx + 1);
                                setTranslateValue(prevState => prevState - oneElementHeight);
                            }
                        }}
                        icon={{
                            type: ButtonIconTypes.Element,
                            value: (
                                <img src="/theme/images/product-select-horizontal/add.svg" />
                            ),
                            position: ButtonIconPositions.Before,
                        }}
                    />
                </div>
                <div
                    className={classnames('progress-wrapper__background', {
                        'bg-green': choosedValue === maxValue,
                    })}
                    style={{ transform: `translateX(${widthPercent})` }}
                />
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutProductSelectHorizontal;
